@media screen and (max-width: 400px) {
  #nosotros {
    padding: 20px;
    width: 111%;
  }

  #ubicacion,
  #actividades,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #galeria {
    width: 110%;
  }
}